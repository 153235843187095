(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/helper-get-team-url/assets/javascripts/get-team-url.js') >= 0) return;  svs.modules.push('/components/marketplace/helper-get-team-url/assets/javascripts/get-team-url.js');

'use strict';

const {
  encode
} = svs.utils.url;
const getTeamUrl = function (groupId, teamName) {
  for (var _len = arguments.length, additionals = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    additionals[_key - 2] = arguments[_key];
  }
  return "/lag/".concat(groupId, "-").concat(encode(teamName)).concat(additionals.length ? '/'.concat(additionals.map(additonal => encode("".concat(additonal))).join('/')) : '');
};
const getRetailUrl = function (groupId, retailerName) {
  for (var _len2 = arguments.length, additionals = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    additionals[_key2 - 2] = arguments[_key2];
  }
  return "/ombudslag/".concat(groupId, "-").concat(encode(retailerName)).concat(additionals.length ? '/'.concat(additionals.map(additonal => encode("".concat(additonal))).join('/')) : '');
};

setGlobal('svs.components.marketplace.helpers', {
  getTeamUrl,
  getRetailUrl
});

 })(window);